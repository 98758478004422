import React, { FC, ReactElement, RefObject, useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, BoxProps } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import { IconCloseV2, IconSearch, Navbar } from '../../components';
import { CurrentUser } from '../../types/sharetribe/currentUser';
import { getSearchInitialValuesFn, isAnyFilterActive } from '../../util/search';
import { useShopConfig, useShopConfigV2 } from '../../hooks/shopConfig';
import { getHandleSearchChangedValueFn } from './LandingPageV2.helpers';
import { Filter, FilterId, SearchNavBarSections, SortConfig } from '../../types/filters/filters';
import { keywordFilterConfig } from '../../shopConfig/filters/marketplace-custom-config';
import FilterCheckbox from '../../components/FilterCheckbox/FilterCheckbox';
import FiltersComponentMobile from '../../components/FiltersComponentMobile/FiltersComponentMobile';
import { getKeywordQueryParam } from '../../components/KeywordFilter/KeywordFilter';
import SortByRadio from '../../components/SortByRadio/SortByRadio';
import AppContext from '../../context/AppContext';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useWindowSize } from '../../hooks/useWindowSize';
import { FrenzyApiModes } from '../../types/frenzy/query';
import FilterComponent from './FilterComponent';
import css from './LandingPageV2.module.css';
import { Feature } from '../../util/featureFlags';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { addSubLabelsToFilterOptions } from '../../util/filter';

export const MAX_MEDIUM_SEARCH_NAVBAR_WIDTH = 1200;

interface SearchNavbarProps {
  toggleIsMobileModalOpen: () => void;
  urlQueryParams: { [key: string]: string[] | string | undefined };
  sortConfig: SortConfig;
  filters: Filter[];
  totalItems: number;
  elRef?: RefObject<HTMLDivElement>;
  topbarHeight: number | null;
}

interface SectionProps {
  wrapperBoxOverrides?: Partial<BoxProps>;
}

interface SectionBaseProps {
  urlQueryParams: { [key: string]: string[] | string | undefined };
  onFilterChange: (values: { [key: string]: string | null }) => void;
}

interface FilterSectionProps extends SectionBaseProps {
  filters: Filter[];
  getInitialValues: (queryParamNames: any) => { [key: string]: string };
}

interface FilterProps extends FilterSectionProps {
  filterSection: SearchNavBarSections;
  className?: string;
}

interface SearchSectionProps extends FilterSectionProps {
  onSearchQueryChange: (values: { [key: string]: string | undefined }) => void;
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  searchQueryInitialValue: string;
}

interface SortBySectionProps extends SectionBaseProps {
  sortConfig: SortConfig;
  isConflictingFilterActive: boolean;
}

interface CondensedFilterSectionProps extends SectionBaseProps {
  filters: Filter[];
  currentQueryParams: { [key: string]: string[] | string | undefined };
  toggleIsMobileModalOpen: () => void;
  setCurrentQueryParams: (value: any) => void;
  sortConfig: SortConfig;
  isConflictingFilterActive: boolean;
  totalItems: number;
}

interface CategoryFilterProps {
  filters: Filter[];
  getInitialValues: (queryParamNames: any) => { [key: string]: string };
  onFilterChange: (values: { [key: string]: string | null }) => void;
}

const Section: FC<SectionProps> = (props) => {
  const { children, wrapperBoxOverrides } = props;

  return (
    <Box display="flex" alignItems="center" className={css.section} {...wrapperBoxOverrides}>
      {children}
    </Box>
  );
};

const SectionItem: FC<SectionProps> = (props) => {
  const { children, wrapperBoxOverrides } = props;

  return (
    <Box mx="4px" {...wrapperBoxOverrides}>
      {children}
    </Box>
  );
};

const Filters = (props: FilterProps) => {
  const { filters, urlQueryParams, getInitialValues, onFilterChange, filterSection, className } =
    props;

  const { shopName } = useShopConfig();

  return (
    <Box display="flex" flexDirection="row">
      {filters
        .filter((f: Filter) => f.searchNavBarSection === filterSection)
        .map((filter) => {
          const filterToUse =
            filter.id === FilterId.IsBrandDirect
              ? addSubLabelsToFilterOptions(filter as Filter, shopName)
              : filter;

          return (
            <FilterComponent
              key={`SearchNavbar.${filter.id}`}
              idPrefix="SearchNavbar"
              filterConfig={filterToUse}
              urlQueryParams={urlQueryParams}
              getInitialValues={getInitialValues}
              onFilterChange={onFilterChange}
              showAsPopup
              className={className}
            />
          );
        })}
    </Box>
  );
};

const CombinedFilters = (props: CategoryFilterProps) => {
  const { filters, getInitialValues, onFilterChange } = props;

  return (
    <Box display="flex" flexDirection="column">
      <FilterCheckbox
        label="Category"
        filters={filters}
        getInitialValues={getInitialValues}
        onSubmit={onFilterChange}
        showAsPopup
      />
    </Box>
  );
};

const FilterSection = (props: FilterSectionProps) => {
  const { filters, urlQueryParams, getInitialValues, onFilterChange } = props;
  const isMobile = useIsMobile();

  const isCombinedFilter = (filter: Filter) => {
    const isFilterSection = filter.searchNavBarSection === SearchNavBarSections.Filter;
    const isCombinedFilterType = filter.id === FilterId.Category || filter.id === FilterId.Style;
    return isFilterSection && isCombinedFilterType;
  };

  const combinedFilters = filters.filter((f) => isCombinedFilter(f));
  const independentFilters = filters.filter((f) => !isCombinedFilter(f));
  const sizeFilter = independentFilters.filter((f) => f.id === FilterId.Size);
  // for mobile we want to limit the amount of filters shown on the filter bar
  const otherIndependentFilters = isMobile
    ? independentFilters.filter((f) => f.id === FilterId.Condition)
    : independentFilters.filter((f) => f.id !== FilterId.Size);

  return (
    <Section>
      <SectionItem>
        <Box display="flex" flexDirection="row">
          {!isEmpty(sizeFilter) && (
            <FilterComponent
              key={`SearchNavbar.${sizeFilter[0].id}`}
              idPrefix="SearchNavbar"
              filterConfig={sizeFilter[0]}
              urlQueryParams={urlQueryParams}
              getInitialValues={getInitialValues}
              onFilterChange={onFilterChange}
              showAsPopup
            />
          )}
          {!isEmpty(combinedFilters) && (
            <CombinedFilters
              filters={combinedFilters}
              getInitialValues={getInitialValues}
              onFilterChange={onFilterChange}
            />
          )}
          <Filters
            filters={otherIndependentFilters}
            urlQueryParams={urlQueryParams}
            getInitialValues={getInitialValues}
            onFilterChange={onFilterChange}
            filterSection={SearchNavBarSections.Filter}
          />
        </Box>
      </SectionItem>
    </Section>
  );
};

const SearchSection = (props: SearchSectionProps) => {
  const {
    filters,
    urlQueryParams,
    getInitialValues,
    onFilterChange,
    onSearchQueryChange,
    searchQuery,
    setSearchQuery,
    searchQueryInitialValue,
  } = props;

  const isSearchModalEnabled = useFeatureFlags(Feature.SearchBarV2);
  const isMobile = useIsMobile();
  const urlParam = getKeywordQueryParam(keywordFilterConfig.queryParamNames);

  const handleSearch = (query: string) => {
    onSearchQueryChange({ [urlParam]: query !== '' ? query : undefined });
  };

  const handleCancelSearch = () => {
    setSearchQuery('');
    onSearchQueryChange({ [urlParam]: undefined });
  };

  const placeholderText = isMobile ? 'Search' : 'Search by style, color, etc.';
  const placeholderStyle = isMobile ? {} : { width: '100%', minWidth: '15em' };

  return (
    <Section wrapperBoxOverrides={{ justifyContent: 'center' }}>
      <SectionItem wrapperBoxOverrides={{ mx: 0 }}>
        <Filters
          filters={filters}
          urlQueryParams={urlQueryParams}
          getInitialValues={getInitialValues}
          onFilterChange={onFilterChange}
          filterSection={SearchNavBarSections.Search}
          className={css.searchSectionItem}
        />
      </SectionItem>
      {!isSearchModalEnabled && (
        <SectionItem wrapperBoxOverrides={placeholderStyle}>
          <SearchBar
            placeholder={placeholderText}
            value={searchQueryInitialValue || searchQuery}
            onChange={(newValue) => setSearchQuery(newValue)}
            onRequestSearch={() => handleSearch(searchQuery)}
            onCancelSearch={handleCancelSearch}
            cancelOnEscape
            classes={{
              root: css.searchSectionItem,
              iconButton: css.iconButton,
            }}
            style={{
              boxShadow: 'none',
              borderRadius: '0',
              height: '39px',
            }}
            searchIcon={<IconSearch />}
            closeIcon={<IconCloseV2 />}
          />
        </SectionItem>
      )}
    </Section>
  );
};

const SortBySection = (props: SortBySectionProps) => {
  const { sortConfig, urlQueryParams, isConflictingFilterActive, onFilterChange } = props;

  return (
    <Section wrapperBoxOverrides={{ justifyContent: 'flex-end' }}>
      <SectionItem>
        <SortByRadio
          urlQueryParams={urlQueryParams}
          sortConfig={sortConfig}
          onSubmit={onFilterChange}
          isConflictingFilterActive={isConflictingFilterActive}
          showAsPopup
        />
      </SectionItem>
    </Section>
  );
};

const CondensedFilterSection = (props: CondensedFilterSectionProps) => {
  const {
    filters,
    urlQueryParams,
    onFilterChange,
    currentQueryParams,
    toggleIsMobileModalOpen,
    setCurrentQueryParams,
    sortConfig,
    isConflictingFilterActive,
    totalItems,
  } = props;

  return (
    <Section>
      <SectionItem>
        <FiltersComponentMobile
          urlQueryParams={urlQueryParams}
          filters={filters}
          currentQueryParams={currentQueryParams}
          toggleIsMobileModalOpen={toggleIsMobileModalOpen}
          setCurrentQueryParams={setCurrentQueryParams}
          onFilterChange={onFilterChange}
          sortConfig={sortConfig}
          isConflictingFilterActive={isConflictingFilterActive}
          totalItems={totalItems}
        />
      </SectionItem>
    </Section>
  );
};

const SearchNavbar: FC<SearchNavbarProps> = (props) => {
  const {
    toggleIsMobileModalOpen,
    urlQueryParams,
    sortConfig,
    filters,
    totalItems,
    elRef,
    topbarHeight,
  } = props;

  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;
  const { treetId } = useContext(AppContext);

  const history = useHistory();
  const { shopName } = useShopConfigV2();

  const windowSize = useWindowSize();
  const isMobile = useIsMobile();

  let styleOverride = {};

  if (topbarHeight) {
    // override css top property for searchNavBar to account for banner heights
    styleOverride = {
      top: topbarHeight,
    };
  }

  const shouldShrinkSearchNavbar =
    isMobile || (!!windowSize.width && windowSize.width < MAX_MEDIUM_SEARCH_NAVBAR_WIDTH);

  const [currentQueryParams, setCurrentQueryParams] = useState(urlQueryParams);
  const [searchQuery, setSearchQuery] = useState('');
  const isSearchBarV2Enabled = useFeatureFlags(Feature.SearchBarV2);

  const getInitialValues = getSearchInitialValuesFn(currentQueryParams, urlQueryParams);
  const urlParam = getKeywordQueryParam(keywordFilterConfig.queryParamNames);
  const searchQueryInitialValue = getInitialValues([urlParam])?.[urlParam];

  useEffect(() => {
    setCurrentQueryParams(urlQueryParams);
    setSearchQuery(searchQueryInitialValue);
  }, [urlQueryParams]);
  const useHistoryPush = true;
  const handleSearchChangedValueFn = getHandleSearchChangedValueFn({
    urlQueryParams,
    currentQueryParams,
    currentUser,
    setCurrentQueryParams,
    sortConfig,
    filterConfig: filters,
    history,
    shopName,
    treetId,
  });
  const onFilterChange = handleSearchChangedValueFn(useHistoryPush, FrenzyApiModes.FilterChange);
  const onFilterChangeNoScroll = handleSearchChangedValueFn(
    useHistoryPush,
    FrenzyApiModes.FilterChange,
    true
  );
  const onSearchQueryChange = handleSearchChangedValueFn(useHistoryPush, FrenzyApiModes.RawQuery);

  const isConflictingFilterActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    urlQueryParams,
    filters
  );

  const filterSection = (
    <FilterSection
      key="filters"
      filters={filters}
      urlQueryParams={urlQueryParams}
      getInitialValues={getInitialValues}
      onFilterChange={onFilterChange}
    />
  );

  const searchSection = (
    <SearchSection
      key="search"
      filters={filters}
      urlQueryParams={urlQueryParams}
      getInitialValues={getInitialValues}
      onFilterChange={onFilterChange}
      onSearchQueryChange={onSearchQueryChange}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      searchQueryInitialValue={searchQueryInitialValue}
    />
  );

  const sortBySection = (
    <SortBySection
      key="sort-by"
      sortConfig={sortConfig}
      urlQueryParams={urlQueryParams}
      isConflictingFilterActive={isConflictingFilterActive}
      onFilterChange={onFilterChange}
    />
  );

  const condensedFilterSection = (
    <CondensedFilterSection
      key="condensed-filter"
      filters={filters}
      urlQueryParams={urlQueryParams}
      onFilterChange={onFilterChangeNoScroll}
      currentQueryParams={currentQueryParams}
      toggleIsMobileModalOpen={toggleIsMobileModalOpen}
      setCurrentQueryParams={setCurrentQueryParams}
      sortConfig={sortConfig}
      isConflictingFilterActive={isConflictingFilterActive}
      totalItems={totalItems}
    />
  );

  let sections: ReactElement[] = [];

  if (isSearchBarV2Enabled) {
    sections = shouldShrinkSearchNavbar
      ? [filterSection, condensedFilterSection]
      : [filterSection, sortBySection];
  } else {
    sections = shouldShrinkSearchNavbar
      ? [searchSection, condensedFilterSection]
      : [filterSection, searchSection, sortBySection];
  }

  return (
    <div ref={elRef} id="search" style={styleOverride} className={css.searchNavbar}>
      <Navbar
        wrapperBoxOverrides={{
          justifyContent: 'space-between',
          overflow: 'visible',
          position: 'relative',
        }}
      >
        {sections}
      </Navbar>
    </div>
  );
};

export default SearchNavbar;
