type ScrollOptions = {
  top?: number | undefined;
  left?: number | undefined;
  behavior?: 'auto' | 'smooth';
};

const windowScroll = (options: ScrollOptions): void => {
  if (typeof window !== 'undefined') {
    window.scroll(options);
  }
};

export default windowScroll;
