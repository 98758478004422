import { Filter } from '../types/filters/filters';

export const addSubLabelsToFilterOptions = (filter: Filter, shopName: string) => {
  const { config } = filter;
  const options = config?.options;

  if (!options) {
    return filter;
  }

  const optionsWithSubLabels = options.map((option) => {
    if (option.label === 'Brand Direct') {
      return { ...option, subLabel: `Sold directly by ${shopName}` };
    }
    if (option.label === 'Peer-to-Peer') {
      return { ...option, subLabel: `Sold by ${shopName} customers` };
    }
    return option;
  });

  return {
    ...filter,
    config: {
      ...config,
      options: optionsWithSubLabels,
    },
  };
};
