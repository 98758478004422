import React from 'react';
import classNames from 'classnames';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconSearchSubscribeProps {
  rootClassName?: string;
  className?: string;
}

const IconSearchSubscribe: React.FC<IconSearchSubscribeProps> = (props) => {
  const { className, rootClassName } = props;

  const { iconColor } = useIconColor();

  return (
    <svg
      className={classNames(rootClassName, className)}
      width="30"
      height="21"
      viewBox="0 0 30 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4216 0.6941L8.47788 3.02655C4.15848 3.46284 0 9.19347 0 14C0 17.866 3.13401 21 7 21C10.866 21 14 17.866 14 14C14 13.6078 13.9677 13.2231 13.9057 12.8485C14.2535 12.9472 14.6206 13 15 13C15.3794 13 15.7465 12.9472 16.0943 12.8485C16.0323 13.2231 16 13.6078 16 14C16 17.866 19.134 21 23 21C26.866 21 30 17.866 30 14C30 9.19347 25.8415 3.46284 21.5221 3.02655L19.5784 0.6941C19.2119 0.254292 18.669 0 18.0965 0C16.9679 0 16.0804 0.964573 16.1741 2.08924L16.1814 2.17734C15.808 2.06207 15.4113 2 15 2C14.5887 2 14.192 2.06207 13.8186 2.17734L13.8259 2.08924C13.9196 0.964573 13.0321 0 11.9035 0C11.331 0 10.7881 0.254292 10.4216 0.6941ZM23 19C25.7614 19 28 16.7614 28 14C28 11.2386 25.7614 9 23 9C20.2386 9 18 11.2386 18 14C18 16.7614 20.2386 19 23 19ZM17 9C17 10.1046 16.1046 11 15 11C13.8954 11 13 10.1046 13 9C13 7.89543 13.8954 7 15 7C16.1046 7 17 7.89543 17 9ZM12 14C12 16.7614 9.76142 19 7 19C4.23858 19 2 16.7614 2 14C2 11.2386 4.23858 9 7 9C9.76142 9 12 11.2386 12 14Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default IconSearchSubscribe;
